import React, { useState, useEffect } from 'react';
import Ruth from '../assets/RuthHeadShot2.jpg'
import AOS from "aos";
import "aos/dist/aos.css";


const testimonials = [
  {

      name: "Stephanie Loffredo",
      title: "Founder, Snatch",
      quote: "Ruth is my first call when I need the best quality design work. She cares about creating intuitive user interfaces that also look good and collaborates with our team to make sure the design becomes a reality."
  },
  {

      name: "Adam Zec",
      title: "CEO, MyStandard.io",
      quote: "Sarah shaped products for our company in the earliest stages. She quickly got up to speed on the business and industry, adapted to ever-changing requirements, and always educated me on what she was doing and why. She worked flawlessly async, and never missed a beat."
  },
  {

      name: "Nicholas Baldo",
      title: "Lead Product Manager, Respondent",
      quote: "Ruth brought the design of our relatively new marketplace software product from a raw to professional state. On personal and professional levels, Ruth is a pleasure to work with."
  },
]

const team = [
  {
      avatar: "https://i.ibb.co/WPSwST2/BestSide.jpg",
      name: "Ruth Zota",
      title: "UX/UI Engineer",
      desc: "Ruth (UX/UI & Visual Design) crafts brand identities & design systems for startups & established companies (John Hopkins, Oscar, Peacock, Titan). 7+ years experience collaborating with founders to translate brands into marketing & product experiences.",
      linkedin: "https://www.linkedin.com/in/ruthzota/",

  },
  {
      avatar: "https://i.ibb.co/hKwcr4X/Other-ezgif-com-webp-to-png-converter.png",
      name: "Lisa Soto",
      title: "Product designer",
      desc: "Sarah is a generalist Product Designer and Marketer. Passionate about user experience (how things work & feel). Recently focused on designing new products for startups (early & growth stages).",
      linkedin: "https://www.linkedin.com/in/lisa-soto-03690217a/",

  },


]





function Team() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
      });
  const [currentTestimonial, setCurrentTestimonial] = useState(0)
  return (
    <section className="py-14 bg-black " id="about">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8" data-aos="fade-up">
            <div className="max-w-4xl mx-auto text-center ">
            <div className='flex items-center justify-center'>
                <h3 className="text-md font-semibold text-white border-[1px] rounded-3xl border-white w-40 font-mono p-2 mb-4 text-center hover:-translate-y-1 hover:scale-100 duration-300">Your team</h3>
                </div>
                <div className="max-w-6xl mx-auto">
                    <h3 className="text-white text-2xl font-semibold md:text-4xl font-mono">
                    Let's build great, big things together
                    </h3>

                </div>

            <div className="mt-12 mb-28">
                    <ul className="grid gap-8 sm:grid-cols-2 md:grid-cols-2">
                        {
                            team.map((item, idx) => (
                                <li key={idx}>
                                    <div className="w-24 h-24 mx-auto">
                                        <img
                                            src={item.avatar}
                                            className="w-full h-full rounded-full object-cover object-left-top"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <h4 className="text-[#FAE7A0] font-semibold sm:text-lg font-mono">{item.name}</h4>
                                        <p className="text-white">{item.title}</p>
                                        <p className="text-gray-400 mt-2">{item.desc}</p>
                                        <div className="mt-4 flex justify-center gap-4 text-gray-400">
                                            <a href={item.linkedin}>
                                                <svg className="w-5 h-5 duration-150 hover:text-gray-500" fill="none" viewBox="0 0 48 48"><g clip-path="url(#clip0_17_68)"><path fill="currentColor" d="M44.447 0H3.544C1.584 0 0 1.547 0 3.46V44.53C0 46.444 1.584 48 3.544 48h40.903C46.407 48 48 46.444 48 44.54V3.46C48 1.546 46.406 0 44.447 0zM14.24 40.903H7.116V17.991h7.125v22.912zM10.678 14.87a4.127 4.127 0 01-4.134-4.125 4.127 4.127 0 014.134-4.125 4.125 4.125 0 010 8.25zm30.225 26.034h-7.115V29.766c0-2.653-.047-6.075-3.704-6.075-3.703 0-4.265 2.896-4.265 5.887v11.325h-7.107V17.991h6.826v3.13h.093c.947-1.8 3.272-3.702 6.731-3.702 7.21 0 8.541 4.744 8.541 10.912v12.572z" /></g><defs><clipPath id="clip0_17_68"><path fill="currentColor" d="M0 0h48v48H0z" /></clipPath></defs></svg>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>



                <ul>
                    {
                        testimonials.map((item, idx) => (
                            currentTestimonial == idx ? (
                                <li key={idx}>
                                    <figure>
                                        <blockquote>
                                            <p className="text-white text-xl font-semibold sm:text-2xl font-mono">
                                                “{item.quote}“
                                            </p>
                                        </blockquote>
                                        <div className="mt-6">

                                            <div className="mt-3" >
                                                <span className="block text-white font-semibold">{item.name}</span>
                                                <span className="block text-gray-600 text-sm mt-0.5">{item.title}</span>
                                            </div>
                                        </div>
                                    </figure>
                                </li>
                            ) : ""
                        ))
                    }
                </ul>
            </div>
            <div className="mt-6" >
                <ul className="flex gap-x-3 justify-center">
                    {
                        testimonials.map((item, idx) => (
                            <li key={idx}>
                                <button className={`w-2.5 h-2.5 rounded-full duration-150 ring-offset-2 ring-[#FAE7A0] focus:ring ${currentTestimonial == idx ? "bg-[#FAE7A0]" : "bg-gray-300"}`}
                                    onClick={() => setCurrentTestimonial(idx)}
                                ></button>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </section>
)
}

export default Team
